<template>
  <div class="row child-component">
    <div class="col-lg-7 col-md-8 col-sm-8 col-xs-6 mx-auto">
      <div class="d-block row col-lg-8 mx-auto">
        <div class="fieldborder" style="margin-top: -22px;">
          <slot name="label" class="lbltxt">
            <span class="lbltxt" style="margin-left: 11px">
              WHAT PRODUCT OR SERVICE WILL THIS VENDOR BE PROVIDING?
            </span>
            <svg style="margin-bottom: 1px; margin-left: 5px" width="9px" height="9px" viewBox="0 0 9 9" version="1.1"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              v-b-tooltip.hover.v-dark.right.html="'(i.e., consulting services)'
                ">
              <g id="LATEST" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <path
                  d="M4.5,0 C2.014875,0 0,2.014875 0,4.5 C0,6.985125 2.014875,9 4.5,9 C6.985125,9 9,6.985125 9,4.5 C8.997375,2.016 6.984,0.002625 4.5,0 Z M4.59375,1.875 C4.90425,1.875 5.15625,2.127 5.15625,2.4375 C5.15625,2.748 4.90425,3 4.59375,3 C4.28325,3 4.03125,2.748 4.03125,2.4375 C4.03125,2.127 4.28325,1.875 4.59375,1.875 Z M5.4375,6.9375 L3.9375,6.9375 C3.7305,6.9375 3.5625,6.7695 3.5625,6.5625 C3.5625,6.3555 3.7305,6.1875 3.9375,6.1875 L4.21875,6.1875 C4.2705,6.1875 4.3125,6.1455 4.3125,6.09375 L4.3125,4.40625 C4.3125,4.3545 4.2705,4.3125 4.21875,4.3125 L3.9375,4.3125 C3.7305,4.3125 3.5625,4.1445 3.5625,3.9375 C3.5625,3.7305 3.7305,3.5625 3.9375,3.5625 L4.3125,3.5625 C4.726875,3.5625 5.0625,3.898125 5.0625,4.3125 L5.0625,6.09375 C5.0625,6.1455 5.1045,6.1875 5.15625,6.1875 L5.4375,6.1875 C5.6445,6.1875 5.8125,6.3555 5.8125,6.5625 C5.8125,6.7695 5.6445,6.9375 5.4375,6.9375 Z"
                  id="info" fill="#00A1D2" fill-rule="nonzero"></path>
              </g>
            </svg>
          </slot>
          <label> </label>
          <b-form-input autocomplete="off" id="entitynameperagreement" class="omniipclass"
            v-model="ProdExpVendor.ProductService" placeholder="Enter Type of Product or Service"
            :maxlength="99"></b-form-input>

        </div>
        <div class="fieldborder">
          <label>What is the anticipated level of business dollars spend you expect to conduct with this vendor annually?
            Enter that amount in the provided space (if services are expected to be ongoing, estimate annual spend)”
          </label>
          <div class="fieldborder">
            <div class="col-md-4" style="margin-left:-3px;">
              <b-form-input autocomplete="off" id="anticipateddollers" class="form-control" placeholder="Enter Dollars"
                v-model="ProdExpVendor.DollarsAnticipatedToConductAnnuallyByVendor" @blur="formatDollars()" @click="SetDirty()"></b-form-input>

            </div>
            <div v-show="!IsBusinessdollarsValid" class="error-message" style="margin-left:12px;">
              This field can only contain numerical values
            </div>
          </div>
        </div>

        <div v-if="this.amt" class="fieldborder" style="margin-top: -25px;">
          <label for="axlocatoin" class="lbltxt">
            Required approvals based off amounts below
          </label>
          <vue-dropzone style="margin-left:12px;" v-on:vdropzone-mounted="mountedmyVueDropzone" :duplicateCheck="true"
            ref="myVueDropzone" id="dropzone" :useCustomSlot="true" class="customdropzone" :include-styling="false"
            :options="dropzoneOptions" v-on:vdropzone-file-added="UploadedToDropzone"
            v-on:vdropzone-removed-file="RemovedFromDropzone">
            <div class="dropzone-custom-content">
              <p class="Drag-and-drop-file-h">
                <b-img style="
                        margin-top: -7px !important;
                        font-family: 'Open Sans';
                      " />
                Drag and drop file here or
                <span class="draganddropspan"> browse </span>

              </p>
            </div>
          </vue-dropzone>

          <div style="padding-left:10px;">
            <span class="extracondition">. Is the amount entered above $20K and up to $75K, attach Finance Director
              approval;</span>
            <br>
            <span class="extracondition">. $75K - $250K, attach CFO approval</span>
            <br>
            <span class="extracondition">. $250K and over, attach Regional CFO approval</span>
          </div>
        </div>

      </div>
    </div>
    <div class="form-group pt-60 text-right  footer-buttons" style="padding-right:96px !important;">
      <button class="btn btn-link text-uppercase" @click="Cancel()">
        CANCEL
      </button>
      <button class="text-uppercase btn custom-button btn-primary" @click="Previous()">
        BACK
      </button>
      <button class="text-uppercase btn custom-button btn-primary" @click="Next()" :disabled="this.$v.$invalid">
        NEXT
      </button>
    </div>
  </div>
</template>

<style scoped>
.form-control[placeholder="Dollers"] {
  background: center right 7px no-repeat url(/../assets/Icons/us-dollar-16.png) #fff !important;
}

.extracondition {
  height: 14px !important;
  font-size: 14px !important;
  font-family: "Open Sans";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.error-message {
  padding-top: 10px;
  padding-bottom: 5px;
  height: 14px !important;
  font-size: 12px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #df5678 !important;
}

label {
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8 !important;
  letter-spacing: normal;
  margin-bottom: 6px;
  padding-left: 12px;
  color: #4d5161;
  text-transform: uppercase;
}

.floating-label-input {
  margin-top: 0px !important;
}

.fieldborder {
  padding-bottom: 20px !important;
}

.omniipclass {
  /* width: 415px !important; */
  height: 35px !important;
  border: #e9eef0 !important;
  /* style="width:415px; height:35px;" */
}

.Text {
  width: 250px;
  height: 196px;
  font-family: "Open Sans";
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.Text .text-style-1 {
  font-weight: 600;
}

.customdropzone {
  height: auto !important;
  border-radius: 8px !important;
  border: dashed 2px #e9eef0 !important;
  background-color: #ffffff !important;
  cursor: pointer !important;
}

.Drag-and-drop-file-h {
  margin-left: 18px !important;
  margin-top: 14px !important;
  width: 387px !important;
  height: 10px !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 0.71 !important;
  letter-spacing: normal !important;
  /* color: #a2a9ad !important; */
}


.zipcodevalidationmsg {
  /* margin-top: -20px !important; */
  width: 157px !important;
  font-size: 10px !important;
  color: #df5678 !important;
  font-family: "Open Sans" !important;
  font-size: 11px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: italic !important;
  line-height: 0.8 !important;
  letter-spacing: normal !important;
  text-align: left !important;
}

.error-message1 {
  height: 14px !important;
  font-size: 10px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #df5678 !important;
}

.error-message {
  height: 14px !important;
  font-size: 10px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #df5678 !important;
}

input#anticipateddollers {
  background-image: url("data:image/svg+xml;utf8,<svg \ xmlns='http://www.w3.org/2000/svg' \ version='1.1' height='40px' width='40px'>\ <text x='2' y='22' \ fill='gray' font-size='15'>$</text></svg>");
  background-repeat: no-repeat;
}

.draganddropspan {
  color: #00a1d2;
}
</style>

<script>
import {
  mapGetters
} from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  validations: {
    ProdExpVendor: {
      ProductService: {
        // required,
        // maxLength:maxLength(99)
        required:required
      },
      DollarsAnticipatedToConductAnnuallyByVendor: { required },
      ServiceInfoFiles: {
        required: requiredIf(function () {
          return this.amt;
        }),
      },
    }
  },
  data() {
    return {
      IsBusinessdollarsValid: true,
      ddl: 0,
      amt: false,
      LesserAmount: false,
      ExpenseType: false,
      FilesUploaded: false,
      dropzoneOptions: {
        closeFilterIcon: require("@/assets/Icons/file.png"),
        previewTemplate: this.template(),
        url: `${process.env.VUE_APP_API_ROOT}api/v1/ProfileUnlock/ProfileUpload`,
        uploadMultiple: true,
        maxFiles: 5,
        minFiles: 1,
        maxFilesize: 10,
        addRemoveLinks: false,
        parallelUploads: 5,
        createImageThumbnails: false,
        autoProcessQueue: false,
        init: function () {
          this.on("addedfile", function (file) {
            if (this.files.length > 5) {
              this.removeFile(file);
              window.alert('Maximum File Upload limit is 5!');
            }
          });
        },
      }
    }
  },
  components: {
    vueDropzone: vue2Dropzone
  },
  props: {
        id: {
            type: Number,
            required: false,
        },
        isDraft: {
            type: Boolean,
            required: false
        }
  },
  mounted() {
    this.formInvalid();
    this.formatDollars();
  },
  computed: {
    ...mapGetters('prodexpvendor', {
      ProdExpVendor: 'ProdExpVendor',
    }),
    IsEdit() {
      return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
    },
  },
  activated() {
    this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
  },
  watch: {
    "ProdExpVendor.IsEditDataLoaded": function (val) {
      if (this.IsEdit == true && val == true) {
        this.ManuallyUploadFiles(this.ProdExpVendor.WireInfoSheets);
      }
    },
    "ProdExpVendor.DollarsAnticipatedToConductAnnuallyByVendor": function (newVal) {
      if (newVal) {
        this.formInvalid()
      }
    }
  },
  methods: {
    SetDirty() {
      this.ProdExpVendor.DollarsAnticipatedToConductAnnuallyByVendor = '';
    },
    Cancel() {
      this.$router.push("/VendorRequests");
    },
    template: function () {
      return `<div class="dz-preview dz-file-preview" style='background-color:#ffffff !important;display:flex;'>

                <div class="dz-details" style='margin-right:79px !important;'>
                    <div class="dz-size" style="display:none;" ><span data-dz-size></span></div>
                    <div class="FileforNewClientxls-Copy" style="margin-left:20px;width:280px;cursor:default !important;" ><span style='word-break:break-all !important;font-size:14px !important;font-weight:400;font-stretch:normal;font-style: normal;line-height: 0.71;color: #00a1d2 !important; font-family:"Open Sans" !important;' data-dz-name></span></div>
                </div>
                <a>
                 <img data-dz-remove=""  style='' src='/icons/fileremove.svg'  @click="DeleteServerFile()" ></img>
                </a>
            </div>`;
    },
    formatDollars() {
      this.IsBusinessdollarsValid = true;
      if (this.ProdExpVendor.DollarsAnticipatedToConductAnnuallyByVendor && this.ProdExpVendor.DollarsAnticipatedToConductAnnuallyByVendor != '') {
        var num = this.ProdExpVendor.DollarsAnticipatedToConductAnnuallyByVendor.toString().replace(',', '');

        num = Number(num);
        if (!isNaN(num)) {
          let temp = num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          this.ProdExpVendor.DollarsAnticipatedToConductAnnuallyByVendor = temp;
        }
        else {
          this.IsBusinessdollarsValid = false;
          this.ProdExpVendor.DollarsAnticipatedToConductAnnuallyByVendor = '';
        }
      }
    },
    Next() {
      this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
      if (this.id) {
        this.$router.push({
          name: "Production Expense Vendor Relationship",
          params: {
            id: this.id,
            isDraft: this.isDraft
          }
        });
      } else {
        this.$router.push({
          name: "Production Expense Vendor Relationship"
        });
      }
    },
    Previous() {
      if (this.id) {
        this.$router.push({
          name: "Production Expense Vendor Banking Info",
          params: {
            id: this.id,
            isDraft: this.isDraft
          }
        });
      } else {
        this.$router.push({
          name: "Production Expense Vendor Banking Info"
        });
      }
    },
    mountedmyVueDropzone() {
      if (this.ProdExpVendor.ID > 0 && this.FilesUploaded == false) {
        this.ManuallyUploadFiles(this.ProdExpVendor.ServiceInfoFiles);
        this.$set(this.ProdExpVendor, "ServiceInfoFiles", this.GetFilesObject());
      }
    },
    UploadedToDropzone() {
      this.ddl = this.$refs.myVueDropzone.dropzone.files.length;
      this.$set(this.ProdExpVendor, "ServiceInfoFiles", this.GetFilesObject());
    },
    formInvalid() {
      if (this.ProdExpVendor.DollarsAnticipatedToConductAnnuallyByVendor && this.ProdExpVendor.DollarsAnticipatedToConductAnnuallyByVendor != null) {
        let businessDollarsAmt = parseInt(this.ProdExpVendor.DollarsAnticipatedToConductAnnuallyByVendor.toString().replace(',', ''));
        if (businessDollarsAmt > 20000) {
          this.amt = true;
        } else {
          this.amt = false;
        }
      }
    },
    RemovedFromDropzone() {
      this.ddl = this.$refs.myVueDropzone.dropzone.files.length;
      this.$set(this.ProdExpVendor, "ServiceInfoFiles", this.GetFilesObject());
    },
    ManuallyUploadFiles(files) {
      if (this.$refs.myVueDropzone != null) {
        if (files != null && files.length > 0 && this.FilesUploaded == false) {
          files.forEach((fileUploaded) => {
            let file = {
              size: fileUploaded.Length,
              name: fileUploaded.Name,
              type: fileUploaded.Extension,
            };
            var url = fileUploaded.FullName;
            this.$refs.myVueDropzone.manuallyAddFile(file, url);
          });
        }
        this.FilesUploaded = true;
      }
    },
    GetFilesObject() {
      let files = [];
      if (this.$refs.myVueDropzone.dropzone.files && this.$refs.myVueDropzone.dropzone.files.length > 0) {
        let filesExists = this.$refs.myVueDropzone.dropzone.files;
        for (let i = 0; i < filesExists.length; i++) {
          files.push({
            name: filesExists[i].name, file: filesExists[i]
          });
        }
        return files;
      } else {
        return files;
      }
    },
  }
}
</script>